import React, { useState, useEffect } from "react";
import { useAccount, useWalletClient } from "wagmi";
import { BrowserProvider } from "ethers";
import SmartImg from "./SmartImg";
import SmartURL from "./SmartURL";
import MintNFT from "./MintNFT.tsx";

const ClaimToken = ({
	claimId,
	imageSrc,
	fileSrc,
	alt,
	active = true,
	mintButtonText,
	claimButtonText,
}) => {
	const [provider, setProvider] = useState(null);
	const { address, isConnected } = useAccount();
	const { data: walletClient } = useWalletClient();

	// Set up ethers provider when wallet client changes
	useEffect(() => {
		if (walletClient) {
			const provider = new BrowserProvider(walletClient);
			setProvider(provider);
		}
	}, [walletClient]);

	return (
		<div className="token-card">
			{active ? (
				<MintNFT
					claimId={claimId}
					mintButtonText={mintButtonText}
					claimButtonText={claimButtonText}
					provider={provider}
					isLoggedIn={provider && isConnected}
					userAddress={address}
				/>
			) : null}
			<div className="token-card-download">
				<p>{alt}</p>
			</div>
			<a href={SmartURL(fileSrc)}>
				<SmartImg src={imageSrc} alt={alt} />
			</a>
			{!active ? (
				<>
					<br />
					<p>Claim Ended</p>
					<br />
				</>
			) : null}
		</div>
	);
};

export default ClaimToken;
