import React, { useEffect, useState } from "react";
import Article from "./Article";
import WalletConnect from "./WalletConnect";
import { useAccount } from "wagmi";

const DelLeaderboard = () => {
	const [leaderboardData, setLeaderboardData] = useState([]);
	const [userData, setUserData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const {
		address: connectedAddress,
		isConnecting,
		isReconnecting,
		isConnected,
		status,
	} = useAccount({
		onConnect: (data) => {
			console.log("Wagmi account connected:", data);
			fetchData();
		},
		onDisconnect: () => {
			console.log("Wagmi account disconnected");
			setUserData(null);
		},
	});

	console.log("DelLeaderboard render state:", {
		connectedAddress,
		isConnecting,
		isReconnecting,
		isConnected,
		hasUserData: !!userData,
		status,
	});

	const fetchData = async () => {
		try {
			console.log("Starting data fetch...");
			setIsLoading(true);
			const baseUrl = process.env.REACT_APP_API_DEL_LEADERBOARD.replace(
				/\/$/,
				""
			);

			// Fetch leaderboard data
			console.log("Fetching leaderboard data...");
			const leaderboardResponse = await fetch(`${baseUrl}/api/leaderboard`);
			if (!leaderboardResponse.ok) {
				throw new Error("Failed to fetch leaderboard data");
			}
			const leaderboardResult = await leaderboardResponse.json();
			console.log(
				"Leaderboard data received:",
				leaderboardResult.length,
				"entries"
			);
			setLeaderboardData(leaderboardResult);

			// If user is connected, fetch their data
			if (connectedAddress && isConnected) {
				console.log("Fetching user data for:", connectedAddress);
				const userResponse = await fetch(
					`${baseUrl}/api/user/${connectedAddress}`
				);
				if (userResponse.ok) {
					const userData = await userResponse.json();
					console.log("User data received:", userData);
					setUserData(userData);
				} else {
					console.log("No user data found");
					setUserData(null);
				}
			} else {
				console.log(
					"No connected address or not connected, clearing user data"
				);
				setUserData(null);
			}

			setIsLoading(false);
		} catch (err) {
			console.error("Error in fetchData:", err);
			setError(err.message);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		console.log("DelLeaderboard useEffect triggered:", {
			connectedAddress,
			isConnecting,
			isReconnecting,
			isConnected,
			status,
		});

		if (!isConnecting && !isReconnecting) {
			fetchData();
		}
	}, [connectedAddress, isConnected]);

	// Helper function to truncate addresses
	const truncateAddress = (address) => {
		return `${address.slice(0, 6)}...${address.slice(-4)}`;
	};

	return (
		<div className="article-container">
			<Article
				title="Corporate Loyalty Leaderboard"
				content={[
					<>
						<p>
							The Del Complex Corporate Loyalty Leaderboard represents a
							quantified measure of dedication to our ecosystem.
						</p>
						<p>
							Rankings are determined by the ownership of Archival Media
							Records, key proprietary assets, including NFTs tied to our
							integrated systems, and active participation in Season 1 of the
							Brain Worm Simulation System.
						</p>
						<p>
							This leaderboard embodies the spirit of competitive excellence and
							commitment to advancing Del Complex's mission. Updates occur in
							real time, providing a transparent view of individual
							contributions to accelerating humanity.
						</p>
						<br />
						<br />
						<WalletConnect />
						{isLoading ? (
							<div className="loading">Loading leaderboard data...</div>
						) : error ? (
							<div className="error">Error: {error}</div>
						) : (
							<div className="leaderboard-container">
								<table className="leaderboard-table">
									<thead>
										<tr>
											<th>Rank</th>
											<th>User</th>
											<th>Total Points</th>
											<th className="blankSpacer"></th>
											<th>Archival</th>
											<th>B.W.S.S.</th>
										</tr>
									</thead>
									<tbody>
										{userData && (
											<>
												<tr className="connected-user">
													<td>{userData.rank}</td>
													<td>
														{userData.ens_name || (
															<>
																<span className="truncated-address">
																	{truncateAddress(userData.wallet_address)}
																</span>
																<span className="full-address">
																	{userData.wallet_address}
																</span>
															</>
														)}
													</td>
													<td>{userData.total_points.toLocaleString()}</td>
													<td className="blankSpacer"></td>
													<td>{userData.nft_points.toLocaleString()}</td>
													<td>{userData.pvp_points.toLocaleString()}</td>
												</tr>
												<tr className="separator-row">
													<td colSpan="6"></td>
												</tr>
											</>
										)}
										{leaderboardData.map((entry, index) => (
											<tr key={entry.wallet_address}>
												<td>{index + 1}</td>
												<td>
													{entry.ens_name || (
														<>
															<span className="truncated-address">
																{truncateAddress(entry.wallet_address)}
															</span>
															<span className="full-address">
																{entry.wallet_address}
															</span>
														</>
													)}
												</td>
												<td>{entry.total_points.toLocaleString()}</td>
												<td className="blankSpacer"></td>
												<td>{entry.nft_points.toLocaleString()}</td>
												<td>{entry.pvp_points.toLocaleString()}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						)}
					</>,
				]}
				titleLevel={1}
			/>
			<style jsx>{`
				.leaderboard-container {
					margin: 20px 0;
					overflow-x: auto;
				}

				.leaderboard-table {
					width: 100%;
					border-collapse: collapse;
					background: rgba(0, 0, 0, 0.2);
					border: 1px solid rgba(255, 255, 255, 0.1);
				}

				.leaderboard-table th,
				.leaderboard-table td {
					padding: 12px;
					text-align: center;
					border-bottom: 1px solid rgba(255, 255, 255, 0.1);
				}

				.leaderboard-table th {
					background: rgba(0, 0, 0, 0.3);
					font-weight: bold;
					text-transform: uppercase;
					font-size: 0.9em;
				}

				.leaderboard-table tr:hover {
					background: rgba(255, 255, 255, 0.05);
				}

				.loading,
				.error {
					text-align: center;
					padding: 20px;
					font-style: italic;
				}

				.error {
					color: #ff6b6b;
				}

				.leaderboard-table th.blankSpacer,
				.leaderboard-table td.blankSpacer {
					width: 20px;
					border-left: 1px solid rgba(255, 255, 255, 0.1);
				}

				.leaderboard-table th:nth-child(5),
				.leaderboard-table th:nth-child(6),
				.leaderboard-table td:nth-child(5),
				.leaderboard-table td:nth-child(6) {
					font-size: 0.7em;
					opacity: 0.7;
				}

				@media (max-width: 768px) {
					.leaderboard-table {
						font-size: 0.9em;
					}

					.leaderboard-table th:nth-child(5),
					.leaderboard-table th:nth-child(6),
					.leaderboard-table td:nth-child(5),
					.leaderboard-table td:nth-child(6) {
						font-size: 0.65em;
					}

					.leaderboard-table th,
					.leaderboard-table td {
						padding: 8px;
					}
				}

				.full-address {
					position: absolute;
					width: 1px;
					height: 1px;
					padding: 0;
					margin: -1px;
					overflow: hidden;
					clip: rect(0, 0, 0, 0);
					white-space: nowrap;
					border: 0;
				}

				.truncated-address {
					display: inline-block;
				}

				.blankSpacer {
					width: 20px;
					opacity: 0.7;
					border-left: 1px solid rgba(255, 255, 255, 0.1);
				}

				.connected-user {
					background: rgba(255, 255, 255, 0.1);
				}

				.connected-user:hover {
					background: rgba(255, 255, 255, 0.15) !important;
				}

				.separator-row {
					height: 20px;
					background: transparent;
				}

				.separator-row:hover {
					background: transparent !important;
				}

				.separator-row td {
					border-bottom: none;
				}
			`}</style>
		</div>
	);
};

export default DelLeaderboard;
