import React, { useEffect } from "react";
import { ConnectKitButton } from "connectkit";
import styled from "styled-components";
import { isMetaMaskBrowser, isIOSDevice, openInMetaMask } from "./metamask.ts";
import { useAccount } from "wagmi";

const LEADERBOARD_API_URL =
	process.env.REACT_APP_LEADERBOARD_API_URL ||
	"https://brainworms-leaderboard-b24268b58840.herokuapp.com/";

const ConnectKitCustomButton = ({ extraStyles }) => {
	const { address, isConnected } = useAccount();

	useEffect(() => {
		if (isConnected && address) {
			fetch(`${LEADERBOARD_API_URL}/api/refresh-wallet`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ walletAddress: address }),
			})
				.then((response) => response.json())
				.catch((error) => console.error("Error refreshing wallet:", error));
		}
	}, [isConnected, address]);

	let StyledButton = styled.button`
		cursor: pointer;
		position: relative;
		display: inline-block;
		margin-top: 1em;
		margin-bottom: 1em;
		background-color: rgba(49, 49, 49, 0.54);
		color: white;
		outline-color: white;
		border: 1px solid white;
		padding: 0.5rem;
		border-radius: 0.5rem;

		&:hover {
			background-color: white;
			color: black;
		}
		&:active {
			background-color: black;
			color: white;
		}
	`;

	if (extraStyles) {
		StyledButton = styled(StyledButton)`
			${extraStyles}
		`;
	}

	// If on iOS and not in MetaMask browser, show direct MetaMask button
	if (isIOSDevice() && !isMetaMaskBrowser()) {
		return (
			<StyledButton onClick={openInMetaMask}>Open in MetaMask</StyledButton>
		);
	}

	// Otherwise show normal ConnectKit button
	return (
		<ConnectKitButton.Custom>
			{({ isConnected, show, truncatedAddress, ensName }) => {
				return (
					<StyledButton onClick={show}>
						{isConnected ? ensName ?? truncatedAddress : "Connect Wallet"}
					</StyledButton>
				);
			}}
		</ConnectKitButton.Custom>
	);
};

export default ConnectKitCustomButton;
