import React, { useState, useEffect } from "react";
import { useAccount, useWalletClient } from "wagmi";
import { BrowserProvider } from "ethers";
import SmartImg from "./SmartImg";
import SmartURL from "./SmartURL";
import MintNFT from "./MintNFT.tsx";

const MintToken = ({
	claimId,
	imageSrc,
	fileSrc,
	alt,
	active = true,
	priceOverride,
	mintButtonText,
	requiredChainId,
}) => {
	const [provider, setProvider] = useState(null);
	const { address, isConnected } = useAccount();
	const { data: walletClient } = useWalletClient();

	// Set up ethers provider when wallet client changes
	useEffect(() => {
		if (walletClient) {
			const provider = new BrowserProvider(walletClient);
			setProvider(provider);
		}
	}, [walletClient]);

	return (
		<div className="token-card-mint">
			<div className="token-card-download">
				<p>
					<a href={SmartURL(fileSrc)}>{alt}</a>
				</p>
			</div>
			<a href={SmartURL(fileSrc)}>
				<SmartImg src={imageSrc} alt={alt} />
			</a>

			{active ? (
				<MintNFT
					claimId={claimId}
					isMint={true}
					priceOverride={priceOverride}
					mintButtonText={mintButtonText}
					requiredChainId={requiredChainId}
					provider={provider}
					isLoggedIn={provider && isConnected}
					userAddress={address}
				/>
			) : (
				<p>Mint Ended.</p>
			)}
		</div>
	);
};

export default MintToken;
