// Main.js
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useLocation,
} from "react-router-dom";

import { useEffect, useState, useMemo, useContext } from "react";
import { VideoProvider } from "./context/VideoContext";
import { BackgroundVideo } from "./components/BackgroundVideo";
import { Navigation } from "./components/Navigation";
import { ThemeProvider } from "./context/ThemeContext";

// Wallet only content
import ClaimStaffToken from "./components/ClaimStaffToken";

// 404 page
import PageNotFound from "./components/PageNotFound";

// Import the component for the registration form
import RegistrationForm from "./components/RegistrationForm";
import RegistrationCandidates from "./components/RegistrationCandidates";
import PreferredCandidates from "./components/PreferredCandidates";
import RegistrationFormContractor from "./components/RegistrationFormContractor";
import StaffOnboarding from "./components/StaffOnboarding";

// main pages
import TransitionOverlay from "./components/TransitionOverlay";
import HomePage from "./components/HomePage";
import About from "./components/About";
import Facilities from "./components/Facilities";
import Careers from "./components/Careers";
import ResearchOverview from "./components/ResearchOverview";
import AGI from "./components/AGI";
import BCI from "./components/BCI";
import Global from "./components/Global";
import Robotics from "./components/Robotics";
import Energy from "./components/Energy";
import ArchivalMedia from "./components/ArchivalMedia";
import ContactUs from "./components/ContactUs";
import Updates from "./components/Updates";
import Products from "./components/Products";

// intranet stuff
import Intranet from "./components/Intranet";
import PurpleSmokeDashboard from "./components/PurpleSmokeDashboard";
import PurpleSmokeRequestAccess from "./components/PurpleSmokeRequestAccess";
import PurpleSmokeViolation from "./components/PurpleSmokeViolation";
import XioSkyETR from "./components/XioSkyETR";
import XioSkyETR2 from "./components/XioSkyETR2";
import SysLogRequestLine from "./components/SysLogRequestLine";

// intranet onboarding
import VisitorPassClaim from "./components/VisitorPassClaim";
import JrEmployeeOnboarding from "./components/JrEmployeeOnboarding";

// xiosky contractor episode 2
import XioSkyContractorRegistration from "./components/XioSkyContractorRegistration";
import XioSkyContractorRegistrationEnergy from "./components/XioSkyContractorRegistrationEnergy";
import ContactXioSky from "./components/ContactXioSky";

// poison data
import VonGoomRegister from "./components/VonGoomRegister";
import vonGoom from "./components/vonGoom";

// blue sea frontier
import BlueSeaFrontier from "./components/BlueSeaFrontier";

// updates pages
import CareersTransparencyReport from "./components/CareersTransparencyReport";
import DeveloperConference from "./components/DeveloperConference";
import HiringEvent from "./components/HiringEvent";
import OnboardingUpdate from "./components/OnboardingUpdate";

// Q1 2024
import PsynetRegister from "./components/PsynetRegister";
import PsynetUpdate from "./components/PsynetUpdate";
import LostAndFound from "./components/LostAndFound";
import LLostAndFound from "./components/LLostAndFound";
import LostAndFFound from "./components/LostAndFFound";
import MediaServiceMintPage from "./components/MediaServiceMintPage";

// Brain Worms
import BrainWorms from "./components/BrainWorms";
import HumanSubject from "./components/HumanSubject";
import BrainWormsSimulator from "./components/BrainWormsSimulator";
import BrainWormsSimulatorLanding from "./components/BrainWormsSimulatorLanding.jsx";
import Colony from "./components/Colony";
import ChimeraAccessPortal from "./components/ChimeraAccessPortal";
import BlueDragonRecords from "./components/bluedragonrecords";

// medicalprovider/login
import BrainWormDoctorLogin from "./components/BrainWormDoctorLogin";
// medicalprovider/portal
import BrainWormDoctorDashboard from "./components/BrainWormDoctorDashboard";
// medicalprovider/request
import BrainWormDoctorRequestAccess from "./components/BrainWormDoctorRequestAccess";
// medicalprovider/contactadmin
import BrainWormDoctorContactAdmin from "./components/BrainWormDoctorContactAdmin";

import DelLeaderboard from "./components/DelLeaderboard";

// footer stuff
import Footer from "./components/Footer";
import ResearchCards from "./components/ResearchCards";
import ProductCards from "./components/ProductCards";
import UpdatesCards from "./components/UpdatesCards";
import ArchivalCards from "./components/ArchivalCards";

import { ThemeContext } from "./context/ThemeContext";
import { useAccount, useContractReads } from "wagmi";

const normalizePath = (path) => {
	if (path === "/") return path; // Avoid removing the slash if it's the root path
	return path.toLowerCase().replace(/\/$/, "");
};

import MetaTags from "./components/MetaTags";
const Content = () => {
	const { address, isConnected } = useAccount();

	const location = useLocation();
	const normalizedPath = normalizePath(location.pathname);

	const { setTheme } = useContext(ThemeContext);

	// navless pages
	const noNavPageNames = [
		"vonGoom",
		"lost-and-found",
		"llost-and-found",
		"lost-and-ffound",
		"blue-dragon-records",
		"mediaservice",
		"register",
		"register-contractor",
		"medicalprovider",
		"brainworms-simulator",
		"login-brainworms-simulator",
	].map(normalizePath);

	const isNoNavPage = noNavPageNames.some((page) =>
		location.pathname.toLowerCase().includes(page)
	);

	// invalid pages for claim staff token
	const noStaffTokenPages = [
		"staff-onboarding",
		"onboarding",
		"purplesmoke",
		"internal-archive",
		"internal-delimages-archive",
		"sys/logrequestline",
		"xioskycontractor",
		"contactorxiosky",
		"contactxiosky",
		"vgopreg",
		"vonGoom",
		"lost-and-found",
		"llost-and-found",
		"lost-and-ffound",
		"blue-dragon-records",
		"mediaservice",
		"register",
		"register-contractor",
		"visitor-pass",
		"brainworms",
		"medicalprovider",
		"brainworms-simulator",
		"login-brainworms-simulator",
		"chimera",
	];
	// invalid pages for token ownership hasNFT check
	const noOwnershipCheckPages = [
		"login-brainworms-simulator",
		"archival-media",
		"vonGoom",
	];
	const darkThemePages = ["/login-brainworms-simulator"];

	const noBackgroundVideoPages = [
		"chimera",
		"purplesmoke",
		"internal-archive",
		"internal-delimages-archive",
		"sys/logrequestline",
		"xioskycontractor",
		"contactorxiosky",
		"contactxiosky",
		"vgopreg",
		"vonGoom",
		"lost-and-found",
		"llost-and-found",
		"lost-and-ffound",
		"blue-dragon-records",
		"mediaservice",
		"humansubject",
		"colony",
		"medicalprovider",
		"brainworms-simulator",
		"login-brainworms-simulator",
	].map(normalizePath);

	const NFT_CONTRACT_ADDRESS = "0x882Dd757E02a3420F003Dc2550FEBf58374797e5";
	const ABI = [
		{
			inputs: [
				{ internalType: "address", name: "owner", type: "address" },
				{ internalType: "uint256", name: "id", type: "uint256" },
			],
			name: "balanceOf",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "view",
			type: "function",
		},
	];

	const tokenIds = useMemo(
		() => Array.from({ length: 30 }, (_, i) => i + 1),
		[]
	);
	const { data: nftOwnership, isLoading: isCheckingOwnership } =
		useContractReads({
			contracts: tokenIds.map((id) => ({
				address: NFT_CONTRACT_ADDRESS,
				abi: ABI,
				functionName: "balanceOf",
				args: [address, id],
			})),
			enabled:
				isConnected &&
				!noOwnershipCheckPages.some((page) => normalizedPath.includes(page)),
			watch: true, // re-run when dependencies change
		});

	const ownerHasNFT = useMemo(() => {
		if (
			!nftOwnership ||
			nftOwnership.some((result) => result.status === "loading")
		)
			return null;
		return nftOwnership.map((result) => (result.result || 0) > 0);
	}, [nftOwnership, isConnected]);

	const isNoBackgroundVideoPage = noBackgroundVideoPages.some((page) =>
		location.pathname.toLowerCase().includes(page)
	);

	useEffect(() => {
		const currentPath = normalizePath(location.pathname);
		if (darkThemePages.some((page) => currentPath.includes(page))) {
			setTheme("light");
		}
	}, [location, setTheme]);

	const shouldShowStaffTokenForCurrentPage = () => {
		return (
			!noStaffTokenPages.some((page) => normalizedPath.includes(page)) &&
			normalizedPath !== "/vonGoom"
		);
	};

	return (
		<>
			{!isNoBackgroundVideoPage && <BackgroundVideo />}
			{!isNoNavPage && <Navigation />}

			<div className="main-content">
				{isConnected && shouldShowStaffTokenForCurrentPage() && (
					<ClaimStaffToken />
				)}

				<div id="top"></div>
				<div className="content-container">
					<Routes>
						<Route
							path="/login-brainworms-simulator"
							element={
								<BrainWormsSimulatorLanding authenticated={isConnected} />
							}
						/>
						<Route
							path="/intranet"
							element={
								<Intranet
									ownerHasNFT={ownerHasNFT}
									isCheckingOwnership={isCheckingOwnership}
								/>
							}
						/>
						<Route path="/" element={<HomePage />} />
						<Route path="/about" element={<About />} />
						<Route path="/updates" element={<Updates />} />
						<Route path="/products" element={<Products />} />
						<Route path="/brainworms" element={<BrainWorms />} />
						<Route path="/chimera" element={<ChimeraAccessPortal />} />
						<Route path="/humansubject" element={<HumanSubject />} />
						<Route
							path="/careers-transparency-report"
							element={<CareersTransparencyReport />}
						/>
						<Route path="/loyalty" element={<DelLeaderboard />} />
						<Route
							path="/2023-developer-conference"
							element={<DeveloperConference />}
						/>
						<Route path="/onboarding-update" element={<OnboardingUpdate />} />
						<Route path="/2023-hiring-event" element={<HiringEvent />} />
						<Route path="/facilities" element={<Facilities />} />
						<Route path="/careers" element={<Careers />} />
						<Route path="/research-overview" element={<ResearchOverview />} />
						<Route path="/agi" element={<AGI />} />
						<Route path="/bci" element={<BCI />} />
						<Route path="/global" element={<Global />} />
						<Route path="/robotics" element={<Robotics />} />
						<Route path="/clean-energy" element={<Energy />} />
						<Route path="/archival-media" element={<ArchivalMedia />} />
						<Route path="/contactus" element={<ContactUs />} />
						<Route path="/candidates" element={<RegistrationCandidates />} />
						<Route
							path="/preferred-candidates"
							element={<PreferredCandidates />}
						/>
						<Route path="/blue-sea-frontier" element={<BlueSeaFrontier />} />
						<Route path="/visitor-pass" element={<VisitorPassClaim />} />
						<Route
							path="/xioskycontractor"
							element={<XioSkyContractorRegistration />}
						/>
						<Route path="/staff-onboarding" element={<StaffOnboarding />} />
						<Route
							path="/contactorxiosky"
							element={<XioSkyContractorRegistrationEnergy />}
						/>
						<Route path="/vgopreg" element={<VonGoomRegister />} />
						<Route path="/vonGoom" element={<vonGoom />} />
						<Route path="/contactxiosky" element={<ContactXioSky />} />
						<Route path="/onboarding" element={<JrEmployeeOnboarding />} />
						<Route path="/internal-archive" element={<XioSkyETR />} />
						<Route
							path="/internal-delimages-archive"
							element={<XioSkyETR2 />}
						/>
						<Route path="/sys/logrequestline" element={<SysLogRequestLine />} />
						<Route path="/purplesmoke" element={<PurpleSmokeDashboard />} />
						<Route
							path="/purplesmoke/request"
							element={<PurpleSmokeRequestAccess />}
						/>
						<Route
							path="/purplesmoke/reportviolation"
							element={<PurpleSmokeViolation />}
						/>
						<Route
							path="/medicalprovider/login"
							element={<BrainWormDoctorLogin />}
						/>
						<Route
							path="/medicalprovider/portal"
							element={<BrainWormDoctorDashboard />}
						/>
						<Route
							path="/medicalprovider/contactadmin"
							element={<BrainWormDoctorContactAdmin />}
						/>
						<Route
							path="/medicalprovider/requestaccess"
							element={<BrainWormDoctorRequestAccess />}
						/>
						<Route
							path="/brainworms-simulator"
							element={<BrainWormsSimulator authenticated={isConnected} />}
						/>
						<Route
							path="/blue-dragon-records"
							element={<BlueDragonRecords />}
						/>
						<Route path="/colony" element={<Colony />} />
						<Route path="/psynetregister" element={<PsynetRegister />} />
						<Route path="/psynet-update" element={<PsynetUpdate />} />
						<Route path="/lost-and-found" element={<LostAndFound />} />
						<Route path="/llost-and-found" element={<LLostAndFound />} />
						<Route path="/lost-and-ffound" element={<LostAndFFound />} />
						<Route path="/mediaservice" element={<MediaServiceMintPage />} />
						<Route path="*" element={<PageNotFound />} />
					</Routes>
					{!isNoBackgroundVideoPage && (
						<div className="solid-container">
							<UpdatesCards />
							<ResearchCards />
							<ProductCards />
							<ArchivalCards />
							<Footer />
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export const Main = () => {
	const [theme, setTheme] = useState("light");

	const contextValue = useMemo(
		() => ({
			theme,
			setTheme: (newTheme) => {
				setTheme(newTheme);
			},
		}),
		[theme]
	);

	return (
		<ThemeProvider value={contextValue}>
			<VideoProvider>
				<MetaTags />
				<Routes>
					<Route path="/register" element={<RegistrationForm />} />
					<Route
						path="/register-contractor"
						element={<RegistrationFormContractor />}
					/>
					<Route path="/*" element={<Content />} />
				</Routes>
			</VideoProvider>
		</ThemeProvider>
	);
};

export default Main;
