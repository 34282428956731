import React, { useState } from "react";
import { useChainId, useSwitchChain } from "wagmi";
import { parseEther } from "viem";
import { mainnet } from "wagmi/chains";
import { Contract } from "ethers";
import ManifoldABI from "../ABI/ManifoldABI.json";

const mintContractAddress = "0x26BBEA7803DcAc346D5F5f135b57Cf2c752A02bE";
const claimContractAddress = "0xDb8d79C775452a3929b86ac5DEaB3e9d38e1c006";
const creatorContractAddress = "0x882Dd757E02a3420F003Dc2550FEBf58374797e5";

export function MintNFT({
	isMint = false,
	claimId,
	requiredChainId,
	mintButtonText = "Mint 0.01E",
	claimButtonText = "Claim Now - Free",
	priceOverride,
	provider,
	isLoggedIn,
	userAddress,
}: {
	claimId: string | number;
	requiredChainId?: number;
	isMint?: boolean;
	mintButtonText?: string;
	claimButtonText?: string;
	priceOverride?: string;
	provider: any;
	isLoggedIn: boolean;
	userAddress: string;
}) {
	const chainId = useChainId();
	const { switchChain } = useSwitchChain();
	const [isPending, setIsPending] = useState(false);
	const [isSwitching, setIsSwitching] = useState(false);

	const targetChainId = requiredChainId || mainnet.id;

	const handleSwitchNetwork = async () => {
		setIsSwitching(true);
		try {
			await switchChain({ chainId: targetChainId });
		} catch (error) {
			console.error("Failed to switch network:", error);
		} finally {
			setIsSwitching(false);
		}
	};

	const handleTransaction = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!isLoggedIn || !provider) {
			console.error("No wallet connected or provider not ready");
			return;
		}

		if (chainId !== targetChainId) {
			await handleSwitchNetwork();
		}

		setIsPending(true);
		try {
			const signer = await provider.getSigner();
			const contract = new Contract(
				isMint ? mintContractAddress : claimContractAddress,
				ManifoldABI.abi,
				signer
			);

			const value = parseEther(
				priceOverride ? priceOverride : isMint ? "0.0105" : "0.0005"
			);

			const gasEstimate = await contract.mint.estimateGas(
				creatorContractAddress,
				BigInt(claimId),
				0,
				[],
				userAddress,
				{ value }
			);

			const gasLimit = (gasEstimate * BigInt(120)) / BigInt(100);

			const tx = await contract.mint(
				creatorContractAddress,
				BigInt(claimId),
				0,
				[],
				userAddress,
				{
					value,
					gasLimit,
				}
			);

			await tx.wait();
			console.log("Transaction successful:", tx.hash);
		} catch (error) {
			console.error("Transaction failed:", error);
		} finally {
			setIsPending(false);
		}
	};

	const getButtonText = () => {
		if (isPending) return "Confirming...";
		if (isSwitching) return "Switching...";
		return isMint ? mintButtonText : claimButtonText;
	};

	return (
		<form onSubmit={handleTransaction}>
			<button
				disabled={isPending || !isLoggedIn || isSwitching}
				type="submit"
				style={{ borderRadius: "0.5rem" }}
			>
				{getButtonText()}
			</button>
		</form>
	);
}

export default MintNFT;
