import React from "react";
import { ConnectKitButton } from "connectkit";
import BlackCreateWalletButton from "./BlackCreateWalletButton.jsx";
import ConnectKitCustomButton from "./ConnectKitCustomButton.js";
import { isMetaMaskBrowser, isIOSDevice, openInMetaMask } from "./metamask.ts";

const WalletConnect = () => {
	// Check on component mount if we need to redirect
	React.useEffect(() => {
		// Only redirect if:
		// 1. On iOS
		// 2. Not already in MetaMask browser
		// 3. MetaMask is detected
		// 4. Not on a page that shouldn't redirect
		const shouldRedirect =
			isIOSDevice() && !isMetaMaskBrowser() && window.ethereum?.isMetaMask;

		if (shouldRedirect) {
			openInMetaMask();
		}
	}, []);

	return (
		<div className="walletConnect">
			<ConnectKitCustomButton />
			<BlackCreateWalletButton />
		</div>
	);
};

export default WalletConnect;
