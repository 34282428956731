export const isMetaMaskBrowser = () => {
	return (
		// Check if we're in MetaMask's browser
		window.ethereum?.isMetaMask &&
		navigator.userAgent.includes("MetaMaskMobile")
	);
};

export const isIOSDevice = () => {
	return /iPad|iPhone|iPod/.test(navigator.userAgent);
};

export const openInMetaMask = () => {
	// Get current URL without any query parameters
	const cleanURL = window.location.origin + window.location.pathname;

	if (isIOSDevice()) {
		// iOS needs a different format
		window.location.href = `https://metamask.app.link/dapp/${cleanURL.replace(
			"https://",
			""
		)}`;
	} else {
		// Android format
		window.location.href = `metamask://dapp/${cleanURL}`;
	}
};
