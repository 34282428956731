// components/Web3Provider.js
import React, { useContext } from "react";
import { WagmiProvider, createConfig, http } from "wagmi";
import { mainnet, base } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import { ThemeContext, ThemeProvider } from "../context/ThemeContext";

const queryClient = new QueryClient();

const ConnectKitProviderWithTheme = ({ children }) => {
	const { theme } = useContext(ThemeContext);
	let oppositeTheme = theme === "light" ? "dark" : "light";

	console.log("ConnectKitProvider rendering with theme:", oppositeTheme);

	return (
		<ConnectKitProvider
			theme="default"
			mode={oppositeTheme}
			onConnect={(address) => {
				console.log("ConnectKit onConnect triggered:", address);
			}}
			onChange={(address) => {
				console.log("ConnectKit onChange triggered:", address);
			}}
			customTheme={{
				"--ck-connectbutton-box-shadow": "0 0 0 0.1rem rgba(0, 0, 0, 1)",
				"--ck-connectbutton-font-size": "1.105rem",
				"--ck-font-family": "Source Code Pro",
				"--ck-connectbutton-width": "230px",
			}}
			options={{
				walletConnectVersion: 2,
				embedGoogleFonts: true,
				walletConnectName: "WalletConnect",
				enforceSupportedChains: true,
				// Specific MetaMask configuration
				custom: {
					"metamask:": {
						name: "MetaMask",
						priority: 1,
						showOnMobile: true,
						scannable: true,
					},
				},
				walletConnectConfiguration: {
					projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
					metadata: {
						name: "Del Complex",
						description: "Alternate Reality Corporation",
						url: "https://www.delcomplex.com",
						icons: ["https://xiosky.com/delmedia/logo200x200.png"],
					},
					// Explicitly enable MetaMask
					includeWalletIds: [
						"c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
					], // MetaMask's WalletConnect ID
				},
			}}
		>
			{children}
		</ConnectKitProvider>
	);
};

export const Web3Provider = ({ children, connectToBase }) => {
	console.log("Web3Provider rendering, connectToBase:", connectToBase);

	const createWagmiConfig = (connectToBase) => {
		const chains = connectToBase ? [base, mainnet] : [mainnet, base];

		console.log("Creating Wagmi config:", {
			chains: chains.map((c) => c.id),
			autoConnect: true,
			shimDisconnect: true,
		});

		return createConfig(
			getDefaultConfig({
				chains,
				transports: {
					[mainnet.id]: http(process.env.REACT_APP_MAINNET_ALCHEMY_ID),
					[base.id]: http(process.env.REACT_APP_BASE_ALCHEMY_ID),
				},
				walletConnectProjectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
				appName: "Del Complex",
				appDescription: "Alternate Reality Corporation",
				appUrl: "https://www.delcomplex.com",
				appIcon: "https://xiosky.com/delmedia/logo200x200.png",
				wagmiOptions: {
					shimDisconnect: true,
					UNSTABLE_shimOnConnectSelectAccount: true,
					autoConnect: true,
					onConnect: ({ address, connector }) => {
						console.log("Wagmi onConnect:", { address, connector });
					},
					onDisconnect: () => {
						console.log("Wagmi onDisconnect triggered");
					},
				},
			})
		);
	};

	const config = createWagmiConfig(connectToBase);

	return (
		<ThemeProvider>
			<WagmiProvider config={config}>
				<QueryClientProvider client={queryClient}>
					<ConnectKitProviderWithTheme>{children}</ConnectKitProviderWithTheme>
				</QueryClientProvider>
			</WagmiProvider>
		</ThemeProvider>
	);
};

export default Web3Provider;
